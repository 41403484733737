import React from "react";
import styled from "styled-components";

import Layout from "../components/layout/Layout";
import Section from "../components/layout/Section";
import Header from "../components/layout/Header";
import SelectNewsSite from "../components/news-site/SelectNewsSite";
import NewsSiteList from "../components/news-site/NewsSiteList";

const StyledSelectNewsSite = styled(SelectNewsSite)`
  flex: 1;
  margin-left: 2rem;
  max-width: 400px;
`;

const FundingPage = ({ pageContext }) => {
  const { newsSites, funding } = pageContext;
  const relatedNewsSites = newsSites.filter(site =>
    site.fundings.find(f => f.uuid === funding.uuid)
  );
  return (
    <Layout title={funding.name}>
      <Section minimal>
        <Header>
          <StyledSelectNewsSite newsSites={newsSites} />
        </Header>
      </Section>
      <Section dark>
        <h1>{funding.name}</h1>
        <h2>Funding following news sites</h2>
        <NewsSiteList newsSites={relatedNewsSites} />
      </Section>
    </Layout>
  );
};

export default FundingPage;
